import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Router from './router';
import './i18n';
import store from "./store";
import Loading from 'compoments/Loading';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Loading />
      <Router />
    </Provider>
  </React.StrictMode>
);
