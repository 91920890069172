
import { HashRouter, Route, Routes} from 'react-router-dom';
import Layout from 'compoments/Layout';
import Index from 'views/Index';
import AccountDelete from 'views/AccountDelete';
export default function Router(){
  return (
    <HashRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index  element={<Index />}></Route>
              <Route path="/accountDelete"  element={<AccountDelete />}></Route>
            </Route>
            {/* <Route path='*' element={<NotFound />}></Route> */}
          </Routes>
        {/* </Web3ReactManager> */}
      </HashRouter>
  )
}