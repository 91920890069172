import {
  post,
  // get,
  // del,
  // put
} from './request';
import api from './api';
const http = {
  // 验证码(关闭账户)
  account_close_code: (param: object) => {
    return post(api.ACCOUNT_CLOSE_CODE, param);
  },
  // 关闭账户
  account_close: (param: object) => {
    return post(api.ACCOUNT_CLOSE, param);
  },
}
export default http;

