import { Outlet  } from 'react-router-dom';
import LayoutHeader from './Header';
import LayoutFooter from './Footer';
import './index.scss';
import { isPhone } from 'utils';

export default function Layout(){
  return (<div className="layout-container">
    {/* {isPhone() ? null : <LayoutHeader />} */}
    
    <div className="main-container">
      <Outlet />
    </div>
  <LayoutFooter />
</div>)
}