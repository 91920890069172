import axios from 'axios';
import {message} from 'antd';
const baseURL = process.env.REACT_APP_BASE_URL;
// 创建 axios 实例
const service = axios.create({
  // API 请求的默认前缀
  baseURL,
  timeout: 100000 // 请求超时时间
})

// 请求拦截
service.interceptors.request.use( (config:any) => {
  //登陆后，请求头token设置
  // let token:any = window.sessionStorage.getItem('token')
  // if(token ){//请求头-token
  //   config.headers.common["Authorization"] = `Bearer ${token}`;
  // }
  
  config.headers["terminalType"] = `2`;
  config.headers["langCode"] = `3`;
  return config;
  },
  (error:any) => {
    // 拦截异常处理
    return Promise.reject(error);
  }
);

// 响应拦截 token过期未统一拦截
service.interceptors.response.use((response:any) => {
  const res = response.data;
  if([''].includes(response.config.url)){
    return res
  }
  if(res.code === '200'){
    return res.data;
  } else {
    // if([401].includes(res.code)){
    //   sessionStorage.clear();
    //   window.location.href = window.location.origin;
    //   return
    //   // dispatch(clearUserInfos());
    // }
    // if([4].includes(res.code) && response.config.url.indexOf('login') === -1){
    //   sessionStorage.clear();
    //   window.location.href = window.location.origin;
    //   alert("账号已冻结");
    //   return
    //   // dispatch(clearUserInfos());
    // }
    // message.error({
    //   content:res.msg || res.message || 'ERROR',
    //   style:{
    //     width: '90vw',
    //     wordBreak: 'break-word',
    //     margin: '0 auto'
    //   }
    // });
    return Promise.reject(res);
  } 
},
(error:any) => {
  console.log(error)
  // message.error('请检查服务器是否异常:('+error?.toString()+')');
  return Promise.reject(error);
});

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function get(url:string, params: Object) {
  return new Promise((resolve, reject) => {
    service.get(url, {params: params })
    .then((res:any) => {
      resolve(res);
    }).catch((err:any) => {
      reject(err);
    });
  });
}
/**
 * post方法，
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const post = (url: string, params: Object = {}, headers:any = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
}) => {
  return service.post(url, params, {...headers});
}
/**
 * put方法，
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const put = (url: string, params: Object = {}) => {
  let contentType = 'application/json;charset=UTF-8'
  return service.put(url, params, {
    headers: {
      'Content-Type': contentType,
    },
  });
}
/**
 * delete方法，
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function del(url: string, params: Object = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, {data:params})
    .then((res:any) => {
      resolve(res);
    }).catch((err:any) => {
      reject(err);
    });
  });
}

export default service;