export const isPhone = ()=>{
  return /Mobi|Android|iPhone/i.test(navigator.userAgent)
}

/**
 * 处理链接参数
 * @param url string
 * @returns object
 */
export function dealUrlParam(url:string){
  var arr = decodeURIComponent(url.split('?')[1]).split('&');
  var request:any={};
  var temp=[];
  for(var i = 0;i<arr.length;i++){
      temp = arr[i].split('=');
      request[temp[0]] = temp[1];
  }
  return request;
}