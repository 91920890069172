import {useState,useEffect  } from 'react';
import {Form,Input,Button,message, Modal } from 'antd';
import {useLocation,useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dealUrlParam } from 'utils';
import { useDispatch } from 'react-redux';
import { setLoading } from 'store/global';
import $http from 'requests';
import InfoImg from 'assets/images/icon_warning_blue@2x.png';
import SuccessImg from 'assets/images/icon_chenggong@2x.png'
import './index.scss';
const downTimeDefault = 60 ;
let timer:any = null;
export default function AccountDelete() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [downTimeStart, setDownTimeStart] = useState(0);
  const [downTime, setDownTime] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  let urlParam = dealUrlParam(location.search);
  useEffect(()=>{
    return ()=>{
      timer && clearInterval(timer);
    }
  },[]) 

  useEffect(()=>{
    if(downTimeStart === 0) return
    timer = setInterval(()=>{
      let now = new Date().getTime();
      let t = now - downTimeStart;
      if(t >  downTimeDefault * 1000){
        clearInterval(timer);
        setDownTimeStart(0);
        setDownTime(0)
        return
      }else {
        setDownTime(downTimeDefault - Math.floor(t/1000))
      }
    },1000);
  },[downTimeStart]);


  useEffect(() => {
    setStep(Number(urlParam.step) || 0);
  },[location,urlParam]);
  
  const getCode = () => {
    form.validateFields(['account']).then((data) =>{
      const { account } = data
      setIsCodeLoading(true);
      $http.account_close_code({account}).then((res: any) => {
        messageApi.info({
          content: t('Sent successfully'),
          duration: 3,
          className: 'my-message',
          icon: <img src={SuccessImg} width={32}   alt="icon"/>
        });
        setDownTimeStart(new Date().getTime());
        setDownTime(downTimeDefault);
      }).catch((error)=>{
        messageApi.info({
          content: error?.message?.toString() || 'Error',
          duration: 3,
          className: 'my-message',
          icon: <img src={InfoImg} width={32}  alt="icon"/>
        });
      }).finally(()=>{
        setIsCodeLoading(false);
      })
    }).catch((err)=>{});
  }


  const onStep = () => {
    switch(step){
      case 0: 
        form.validateFields().then(res =>{
          let {account,code } = res;
          navigate(`${location.pathname}?step=${1}&account=${account}&code=${code}`);
        }).catch((err)=>{});
        break;
      case 1: 
        // console.log(urlParam)
        setIsModalOpen(!isModalOpen);
        break;
      default: ;
    }
  }

  const onConfirmCancle = () => {
    setIsModalOpen(!isModalOpen);
  }

  const onConfirmDelete = () => {
    let {step,...otherParam } = urlParam;
    dispatch(setLoading(true));
    $http.account_close(otherParam).then((res: any) => {
      navigate(`${location.pathname}?step=${2}&account=${urlParam.account}`);
      onConfirmCancle();
    }).catch((error:any)=>{
      messageApi.info({
        content: error?.msg?.toString() || 'Error',
        duration: 3,
        className: 'my-message',
        icon: <img src={InfoImg} width={32} alt="icon"/>
      });
    }).finally(()=>{
      dispatch(setLoading(false));
    })
  }

  return (<div className="account-delete-conatainer">
    {contextHolder}
    <div className="content-box">
      <div className="title-text">{t('Delete Account')}</div>
      <div className="steps">
        <div className={`step-item ${step >= 0?'actived':''}`}>
          <div className="number-border">
            <div className="number">1</div>
          </div>
          <div className='description'>{t('Verify LuckyChat account')}</div>
        </div>
        <div className="intervalBlock">
          {new Array(6).fill(0).map((item,index:number)=><span key={index}></span>)}
        </div>
        <div className={`step-item ${step >= 1?'actived':''}`}>
          <div className="number-border">
            <div className="number">2</div>
          </div>
          <div className='description'>{t('Confirm deleting LuckyChat account')}</div>
        </div>
        <div className="intervalBlock">
          {new Array(6).fill(0).map((item,index:number)=><span key={index}></span>)}
        </div>
        <div className={`step-item ${step >= 2?'actived':''}`}>
          <div className="number-border">
            <div className="number">3</div>
          </div>
          <div className='description'>{t('Deleting confirmed')}</div>
        </div>
      </div>
      <div className="content">
        {
          step === 0 ? (
            <Form form={form} layout="vertical"  autoComplete="off" hideRequiredMark={true} className='form-box'>
              <Form.Item name="account" label="Email"  rules={[
                {required: true, message: t('Input account email') || ''},
                { pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: t('Please enter the correct email address') || '' }
              ]} >
                <Input placeholder='Input account email'/>
              </Form.Item>
              <div className="verification-item">
                <Form.Item name="code" label={t('Verification Code')} className='code-item' rules={[
                  {required: true, message: t('Input verification code') || ''}
                ]}>
                  <Input placeholder={t('Input verification code') || ''}/>
                </Form.Item>
                <Form.Item label="" className='code-btn-item'>
                  <Button className='code-btn' onClick={getCode} disabled={isCodeLoading || downTime>0}>
                    {downTime>0?downTime+'s':t('Get verification code')}
                  </Button>
                </Form.Item>
              </div>
              <Button className='next-btn' onClick={onStep}>{t('Next')}</Button>
            </Form>
          ): step === 1 ? (<div className='step-1'>
            <div className="top">
              <img src={require("../../assets/images/icon_warning_red@2x.png")} alt="icon" />
              <span className='label'>{t('When the account is deleted')}：</span>
            </div>
            <div className="description">
            {t("You can't find back the account")}：{urlParam.account}.<br/>
            <p dangerouslySetInnerHTML={{__html: t('deleteDecription') || ''}}></p>
            </div>
            <Button className='delete-btn' onClick={onStep}>{t('Confirm delete')}</Button>
          </div>):(<div className='step-2'>
            <img src={require('../../assets/images/icon_chenggong@2x.png')} alt="icon" />
            <div className="description">
              <p dangerouslySetInnerHTML={{__html: t("You've deleted account <b>%%</b> successfully").replace("%%", urlParam.account)}}></p>
              <p>{t("You can't login or register Luckychat with this account.")}</p>
            </div>
          </div>)
        }
      </div>
      <Modal  
      width={400}
      wrapClassName="confirm-modal"
      maskClosable={false}
      open={isModalOpen} 
      okText="Yes"
      cancelText="No"
      onOk={onConfirmDelete} 
      onCancel={onConfirmCancle}>
        <p className='text'>{t('Dou you confirm to delete account？')}</p>
      </Modal>
    </div>
  </div>)
}