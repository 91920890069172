import { 
  createSlice, 
  PayloadAction,
} from '@reduxjs/toolkit';
// const storage_prefix = 'luckychat';
interface initialStateType {
  loading: boolean;
}
const initialState: initialStateType = {
  loading: false,//加载状态
}

export const publicSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoading: (state: initialStateType, action: PayloadAction<boolean>) => {
      state.loading =  action.payload;
    }
  },
});

export const { 
  // setTheme,
  setLoading
  
} = publicSlice.actions;

export default publicSlice.reducer;