import {  useState, useEffect } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import {useTranslation } from 'react-i18next';
import { setStorageLng } from 'i18n/storage';
import {isPhone} from 'utils';
import { useLocation } from 'react-router-dom';
import './index.scss';
function LayoutHeader() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState(false)
  const items: MenuProps['items'] = [
    {
      key: 'en',
      label: ( <div>English</div>),
    },
    {
      key: 'zh_CN',
      label: ( <div>中文简体</div>),
    },
    {
      key: 'zh_TW',
      label: ( <div>中文繁體</div>),
    },
    {
      key: 'jp',
      label: ( <div>日本语</div>),
    },
    {
      key: 'tr',
      label: ( <div>Türkçe</div>),
    },
    
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll, false)
  }, []);

  function handleScroll() {
    if (window.scrollY > 0) {
      setScrollTop(true)
    } else {
      setScrollTop(false);
    }
  }

  const handleLang = (e: any) => {
    setStorageLng(e.key);
    i18n.changeLanguage(e.key);
  }

  return (
    <div className={`LayoutHeader ${
      ['/'].includes(location.pathname)?
      // (scrollTop ?' bg-color transition':'bg-color transparent')
      'bg-color transparent'
      :'bg-img'
    }`}
    >
      <div className="left">
        <a href="/">
          <img className='logo' src={require(`../../../assets/images/logo_top@2x.png`)} alt="logo" style={{}}/>
        </a>
        {
          location.pathname === '/accountDelete' && (<a className='accountDelete' href={'/#/accountDelete'}>{t('Delete Account')}</a>)
        }
      </div>
      <div className="right">
        {/* <a className="item phone-hide" target='_blank' rel="noreferrer" href="https://twitter.com/LUCKYCHAT2023">
          <img src={require("../../../assets/images/Twitter.png")} alt="Twitter" />
        </a>
        <a className="item phone-hide" target='_blank' rel="noreferrer" href="https://instagram.com/luckychatofficial">
          <img src={require("../../../assets/images/Instagram.png")} alt="Instagram" />
        </a> */}
        {/* <a className="item phone-hide" href="https://t.me/luckychatofficial" target='_blank' rel="noreferrer">
          <img src={require("../../../assets/images/telegram.png")} alt="Telegram" />
        </a> */}
        <div className="item">
          <Dropdown menu={{ items, onClick: handleLang,selectedKeys:[i18n.language] }} overlayClassName='langeDropdown'>
            <a href="void(0)" onClick={(e) => e.preventDefault()} className='langText'>
              <img src={require(`../../../assets/images/language${isPhone()?'@2x':''}.png`)} alt="language" />
              <span>{t('language')}</span>
              <img src={require("../../../assets/images/ArrowDown@2x.png")} alt="down" className='arrowDown' />
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default LayoutHeader;
