import  { useState, useEffect } from 'react';
import {  Modal,
  // Button 
} from 'antd';
// import {  LeftCircleOutlined,RightCircleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import { Document, Page,pdfjs } from "react-pdf";
import './index.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function Pdf({
  type,
  close
}:any){
  const { t,i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [numPages, setNumPages] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);

  // const onDocumentLoadSuccess = ({ numPages }:any)=> {
  //   setNumPages(numPages);
  // }

  useEffect(()=>{
    setIsModalOpen(type?true:false);
  },[type]);


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    close();
  };

  return (
    <Modal width={680} title={t(`home.${type}`)} 
      maskClosable={false}
      wrapClassName="pdf-modal" 
      footer={null} 
      open={isModalOpen} 
      onOk={handleOk} 
      onCancel={handleCancel}>
      <iframe title={type} src={`/html/${type}_${i18n.language}.html`} frameBorder="0" style={{width: '100%',height: '70vh'}}></iframe>
    </Modal>
  )
}