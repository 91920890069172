import {  useState, useEffect,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutHeader from 'compoments/Layout/Header';
import Banner from 'compoments/Banner';
import {isPhone} from 'utils';
import './index.scss';

function Index() {
  const { t,i18n } = useTranslation();
  const [scrollH, setScrollH] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const [bannerIndex, setBannerIndex] = useState(1);
  const [elRefs]:any = useState({
    banner: useRef(null),
    card: useRef(null),
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
    privacyCotent: useRef(null),
    privacyImg: useRef(null),
    liveCenterBg: useRef(null),
    // live1: useRef(null),
    // live2: useRef(null),
  })
  useEffect(() => {
    window.addEventListener('scroll',chengeScroll);
    return ()=>{
      window.removeEventListener('scroll',chengeScroll);
    }
  },[]);

  useEffect(() => {
    // console.log('scrollH',scrollH,elRefs)
    // for(let i in elRefs){
    //   console.log(i,elRefs[i].current?.offsetTop)
    // }
    let status = scrollH < (elRefs.card?.current?.offsetTop || 0) + (elRefs.banner?.current?.clientHeight || 0)
    // let status = scrollH < (elRefs.card?.current?.offsetTop || 0)
    setAutoPlay(status);
  },[scrollH,elRefs]);


  const chengeScroll = () => {
    let currentH = document.documentElement.clientHeight;
    let scrollH = document.documentElement.scrollTop;
    setScrollH(currentH + scrollH);
  }

  const onChange = (currentSlide: number) => {
    console.log(currentSlide,'currentSlide')
    setBannerIndex(currentSlide)
  }

  // const renderButtons = ()=>{
  //   return (<div className="buttons">
  //   <img src={require("../../assets/images/download-google.png")} alt="" />
  //   <img src={require("../../assets/images/download-apple.png")} alt="" />
  // </div>)
  // }
  
  return (
    <div className="home">
      <LayoutHeader />
     
      <div className="banner-container" ref={elRefs.banner}>
        <div className="left">
          {
            bannerIndex === 1? (<div key={1}>
              <h1 dangerouslySetInnerHTML={{__html: `${t('home.banner2.title')}`}}></h1>
              <p>{t('home.banner2.content')}</p>
              {/* {renderButtons()} */}
            </div>
            ) :  
            bannerIndex === 2?(<div  key={2}>
              <h1 dangerouslySetInnerHTML={{__html: `${t('home.banner3.title')}`}}></h1>
              <p>{t('home.banner3.content')}</p>
              {/* {renderButtons()} */}
            </div>
            ) : (<div  key={0}>
              <h1>{t('home.banner1.title')}</h1>
              <p>{t('home.banner1.content')}</p>
              {/* {renderButtons()} */}
            </div>
            )
          }
        </div>
        <div className="right">
          <Banner setIndex={onChange} autoplay={autoPlay}/>
        </div>
      </div>
      <section ref={elRefs.card} className={`card-container ${isPhone() || scrollH > (elRefs.card?.current?.offsetTop)?'show':''}`} >
        <div className="card-list" >
          <div ref={elRefs.card1} className={`card-item`}>
            <div>
              <img src={require(`../../assets/images/free${isPhone()?'@2x':''}.png`)} alt="" />
              <h4>{t('home.card1.title')}</h4>
              <p dangerouslySetInnerHTML={{__html: `${t('home.card1.content')}`}}></p>
            </div>
          </div>
          <div ref={elRefs.card2} className={`card-item`}>
            <div>
              <img src={require(`../../assets/images/team${isPhone()?'@2x':''}.png`)} alt="" />
              <h4>{t('home.card2.title')}</h4>
              <p dangerouslySetInnerHTML={{__html: `${t('home.card2.content')}`}}></p>
            </div>
          </div>
        </div>
        <div className="bg"></div>
      </section>
      <section className="privacy">
        <div ref={elRefs.privacyCotent} className={`left ${isPhone() || scrollH > (elRefs.privacyCotent?.current?.offsetTop)?'fadeInLeft':''}`}>
          <img src={require(`../../assets/images/earth${isPhone()?'@2x':''}.png`)} alt="" />
          <h1>{t('home.privacy.title')}</h1>
          <p>{t('home.privacy.content')}</p>
        </div>
        <div ref={elRefs.privacyImg} className={`right ${isPhone() || scrollH > (elRefs.privacyImg?.current?.offsetTop)?'fadeInRight':''}`}>
          <img src={require(`../../assets/images/${isPhone()?'phone/':''}privacy_simi_${i18n.language}@2x.png`)} alt="" />
        </div>
      </section>
      {/* <section 
        data-lang={i18n.language}
        ref={elRefs.liveCenterBg} className={`live ${isPhone() || scrollH > (elRefs.liveCenterBg?.current?.offsetTop)?'show':''}`} 
      >
        <div 
          className={`live-item`}
          >
          <h1>{t(`home.live.item1.title`)}</h1>
          <p>{t(`home.live.item1.content`)}</p>
        </div>
        <div  
        className={`live-item`}
        style={{'animationDelay': '0.6s'}}>
          <h1>{t(`home.live.item2.title`)}</h1>
          <p>{t(`home.live.item2.content`)}</p>
        </div>
        <div className='live-item live-center-bg' style={{
          display: isPhone()?'block':'none',
          'animationDelay': '0.9s'
          }}>
          <img  src={require(`../../assets/images/${isPhone()?'phone/':''}live_${i18n.language}@2x.png`)} alt="" />
        </div>
        <div 
        style={{'animationDelay': `${0.9+(isPhone()?0.3:0)}s`}}
        className={`live-item`} >
          <h1>{t(`home.live.item3.title`)}</h1>
          <p>{t(`home.live.item3.content`)}</p>
        </div>
        <div 
        style={{'animationDelay':`${1.2+(isPhone()?0.3:0)}s`}}
        className={`live-item`} >
          <h1>{t(`home.live.item4.title`)}</h1>
          <p>{t(`home.live.item4.content`)}</p>
        </div>
      </section> */}
    </div>
  );
}

export default Index;
