import { useSelector } from 'react-redux';
import { AppState } from 'store';
import loadingSvg from 'assets/svg/initialload.svg';

import './index.scss';
export default function Loading(){
    const loading:any = useSelector<AppState>((state:any) => state.global.loading);
    return (
        <div className={`loading-container ${loading?'show':''}`} >
            <div className="loading-box">
            <img
                src={loadingSvg}
                alt="loader"
                width={45}
                className="heartbeat"
                />
            {
            // (<div className='loading-tips'>{'msg'}</div>)
            }
            </div>
        </div>
    )
}