import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { isPhone } from 'utils';
import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';
let timer: any = null;
let autoPlay: any = true;


export default function Banner(props: any) {
  // console.log('autoPlay',autoPlay)
  const { i18n } = useTranslation();
  const [viewWidth, setViewWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const swiperRef = useRef(null);

  useEffect(() => {
    if(isPhone()) return
    init();
    return () => {
      timer && clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    // autoPlay = props.autoplay;
  }, [props.autoplay]);

  useEffect(() => {
    console.log(currentIndex, 'cur')
    props.setIndex && props.setIndex(currentIndex);
  }, [currentIndex]);



  const init = () => {
    if(isPhone()) return
    var box = document.querySelector('.banner-frame');
    setViewWidth(box?.clientWidth || 0);
    var img = document.querySelectorAll(".imgDiv");
    var buttons = document.querySelectorAll('.dotP');
    let index = 1;
    let idArr: any = ['first', 'second', 'last'];

    initialize();
    timer = setInterval(next, 3000);

    for (let i = 0; i < buttons.length; i++) {
      // eslint-disable-next-line
      buttons[i].addEventListener('mousedown', () => {
        clearInterval(timer);
        timer = null;
        if (index > i) {
          let x = index - i;
          while (x--) {
            prev();
          }
        } else if (index < i) {
          let x = i - index;
          while (x--) {
            next();
          }
        }
      })
      // eslint-disable-next-line
      buttons[i].addEventListener('mousedown', () => {
        timer = setInterval(next, 3000)
      })
    }

    function next() {
      // console.log('next',autoPlay)
      if (autoPlay) {
        idArr.unshift(idArr.pop());
        initialize();
        console.log('next')
        if (index >= buttons.length - 1) {
          setCurrentIndex(0);
          index = 0;
        } else {
          setCurrentIndex(index + 1);
          index++;
        }
      }
    }

    function prev() {
      idArr.push(idArr.shift());
      initialize();
      if (index === 0) {
        setCurrentIndex(buttons.length - 1);
        index = buttons.length - 1;
      } else {
        setCurrentIndex(index - 1);
        index = index - 1;
      }
    }

    function initialize() {
      for (let i = 0; i < img.length; i++) {
        img[i].id = idArr[i];
      }
    }
  }

  function slideChange(swiper:any) {
    const index = swiper.activeIndex
    console.log('slideChange',index)
    props.setIndex && props.setIndex(index);
  }
  if (isPhone()) {
    return (
      <Swiper
        ref={swiperRef}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        onSlideChange={(swiper:any) => slideChange(swiper)}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide><div className="imgDiv m-slider"><img src={require(`../../assets/images/phone/sceenpage@2x.png`)} alt="" /></div></SwiperSlide>
        <SwiperSlide><div className="imgDiv m-slider" ><img src={require(`../../assets/images/phone/huihua_${i18n.language}@2x.png`)} alt="" /></div></SwiperSlide>
        <SwiperSlide><div className="imgDiv m-slider"><img src={require(`../../assets/images/phone/shequ_${i18n.language}@2x.png`)} alt="" /></div></SwiperSlide>
      </Swiper>
    )
  }



  return (
    <div className='banner-frame'>
      <div className="out">
        <div className="inner">
          <div className="imgDiv" data-width={viewWidth + 'px'}><img src={require(`../../assets/images/huihua_${i18n.language}@2x.png`)} alt="" /></div>
          <div className="imgDiv" data-width={viewWidth + 'px'}><img src={require(`../../assets/images/shequ_${i18n.language}@2x.png`)} alt="" /></div>
          <div className="imgDiv" data-width={viewWidth + 'px'}><img src={require(`../../assets/images/sceenpage@2x.png`)} alt="" /></div>

          {/* <div className="img"><img src={require("../../assets/images/simi_banner.png")} alt="" /></div> */}
        </div>
        <div className="button">
          {
            new Array(3).fill(0).map((item, i) => {
              return (<p className='dotP' key={i} style={{ background: `rgba(255,255,255,${currentIndex === i ? '1' : '.2'})` }}></p>)
            })
          }
        </div>
      </div>
    </div>
  )
}


// const MBanner: React.FC = () => {
//   const { i18n } = useTranslation();
//   const swiperRef = useRef(null);
//   return (
//     <Swiper
//       ref={swiperRef}
//       autoplay={{
//         delay: 3000,
//         disableOnInteraction: false,
//       }}
//       pagination={{
//         clickable: true,
//       }}
//       onSlideChange={(swiper) => slideChange(swiper)}
//       modules={[Pagination, Autoplay]}
//     >
//       <SwiperSlide><div className="imgDiv m-slider"><img src={require(`../../assets/images/phone/sceenpage@2x.png`)} alt="" /></div></SwiperSlide>
//       <SwiperSlide><div className="imgDiv m-slider" ><img src={require(`../../assets/images/phone/huihua_${i18n.language}@2x.png`)} alt="" /></div></SwiperSlide>
//       <SwiperSlide><div className="imgDiv m-slider"><img src={require(`../../assets/images/phone/shequ_${i18n.language}@2x.png`)} alt="" /></div></SwiperSlide>
//     </Swiper>
//   )
// }