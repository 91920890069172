import {  useState} from 'react';
import { useTranslation } from 'react-i18next';
import PdfPhone from 'compoments/Pdf/html';
import './index.scss';

export default function LayoutFooter(){
  const { t } = useTranslation();
  const [pdfType, setPdfType] = useState('');
  // return (<div ref={elRefs.footer} className={`footer-container ${isPhone() || scrollH > (elRefs.footer?.current?.offsetTop) || 0?'animation__footer':''}`}>
    
  return (<div  className={`layout-footer-container`}><div className="left">
      <img src={require(`../../../assets/images/logo_bottom@2x.png`)} alt="" />
      <p>© Copyright AIM Chat  All Rights Reserved</p>
    </div>
    <div className="center">
      <a href="https://twitter.com/LUCKYCHAT2023"  target='_blank' rel="noreferrer" style={{'animationDelay': '0.6s'}}><img src={require(`../../../assets/images/phone/twitter@2x.png`)} alt="" /></a>
      <a href="https://instagram.com/luckychatofficial"  target='_blank' rel="noreferrer" style={{'animationDelay': '0.9s'}}><img src={require(`../../../assets/images/phone/instagram@2x.png`)} alt="" /></a>
      {/* <a href="https://t.me/luckychatofficial" target='_blank' rel="noreferrer" style={{'animationDelay': '1.2s'}}><img src={require(`../../../assets/images/phone/telegram@2x.png`)} alt="" /></a> */}
    </div>
    <div className="right">
      <span onClick={()=>setPdfType('userAgreement')}>{t('home.userAgreement')}</span>
      <span onClick={()=>setPdfType('privacyPolicy')}>{t('home.privacyPolicy')}</span>
    </div>
    <div className="footer">
    © Copyright AIM Chat  All Rights Reserved
    </div>
    {
      pdfType && (
        // isPhone()?<PdfPhone type={pdfType} close={()=>setPdfType('')}/>:
        <PdfPhone type={pdfType} close={()=>setPdfType('')}/>
      )
      
    }
  </div>
)
}